@use '@/ui/style/sass';

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: sass.ptr(50);
  height: sass.ptr(50);
  transform: translate(-50%, -50%);
  background: url('https://s3.marpple.co/files/u_3040850/2024/9/original/d7b3f1e932fc164e1bbe41959ef919537bc56f1c1.gif')
    no-repeat;
  background-size: sass.ptr(50) sass.ptr(50);
}

@include sass.htmlConditioner('Ciety') {
  .loader {
    background: url('https://s3.marpple.co/files/u_2283830/2025/2/original/4e12285b19005c68d6f2e12d3f0aabceb02b36161.gif')
      no-repeat;
    background-size: sass.ptr(50) sass.ptr(50);
  }
}
