@use '@/ui/style/sass';

.primary {
  background-color: sass.$primary;
  color: sass.$white;
}

.secondary {
  background-color: sass.$secondary;
  color: sass.$primary;
}

.line {
  background-color: sass.$white;
}

.disabled {
  border: none;
  background-color: sass.$gray_10;
  color: sass.$gray_50;
  cursor: default;
  pointer-events: none;
}

.large {
  align-self: stretch;
  width: 100%;
  height: sass.ptr(56);
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out,
    border 200ms ease-out;
  border-radius: sass.ptr(8);
  @include sass.flex(center, center);
}

.medium {
  align-self: stretch;
  width: 100%;
  height: sass.ptr(52);
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out,
    border 200ms ease-out;
  border-radius: sass.ptr(8);
  @include sass.flex(center, center);
}

.small {
  align-self: stretch;
  width: 100%;
  height: sass.ptr(48);
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out,
    border 200ms ease-out;
  border-radius: sass.ptr(8);
  @include sass.flex(center, center);
}

.x_small {
  align-self: stretch;
  width: 100%;
  height: sass.ptr(40);
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out,
    border 200ms ease-out;
  border-radius: sass.ptr(8);
  @include sass.flex(center, center);
}

.button {
  padding: 0 sass.ptr(16);
  white-space: nowrap;
}

@include sass.htmlConditioner('MarppleShop') {
  .primary {
    background-color: sass.$primary;
    color: sass.$white;
  }

  .secondary {
    background-color: sass.$secondary;
    color: sass.$primary;
  }

  .line {
    border: 1px solid sass.$gray_20;
    color: sass.$gray_80;
  }

  .disabled {
    border: none;
    background-color: sass.$gray_10;
    color: sass.$gray_50;
  }
}

@include sass.htmlConditioner('Marpple') {
  .button {
    border-radius: sass.ptr(999);
  }

  .secondary {
    border: 1px solid sass.$primary;
  }

  .line {
    border: 1px solid sass.$black;
    color: sass.$black;
  }

  .disabled {
    border: none;
    background-color: sass.$gray_20;
    color: sass.$white;
  }
}

@include sass.htmlConditioner('Ciety') {
  .button {
    border-radius: sass.ptr(999);
  }

  .primary,
  .secondary {
    color: sass.$gray_2_5;
  }

  .line {
    border: 1px solid sass.$gray_80;
    color: sass.$black;
  }
}

@include sass.htmlConditioner('MarppleShop', 'pc') {
  @media (hover: hover) {
    .primary:hover {
      background-color: sass.$secondary;
      color: sass.$primary;
    }

    .secondary:hover {
      background-color: sass.$primary;
      color: sass.$white;
    }

    .line:hover {
      border: 1px solid sass.$primary;
      color: sass.$primary;
    }
  }
}

@include sass.htmlConditioner('Marpple', 'pc') {
  @media (hover: hover) {
    .primary:hover {
      background-color: sass.$accent;
      color: sass.$primary;
    }

    .secondary:hover {
      border: 1px solid sass.$accent;
      color: sass.$accent;
    }

    .line:hover {
      border: 1px solid sass.$accent;
      color: sass.$accent;
    }
  }
}

@include sass.htmlConditioner('Ciety', 'pc') {
  @media (hover: hover) {
    .primary:hover {
      background-color: sass.$tertiary;
    }

    .secondary:hover {
      background-color: sass.$gray_80;
    }

    .line:hover {
      border: 1px solid sass.$black;
    }
  }
}
