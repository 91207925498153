@use '@/ui/style/sass';

.button {
  position: relative;
  cursor: pointer;
}

.page {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@include sass.htmlConditioner(false, 'pc') {
  .container {
    width: sass.ptr(512);
    margin: sass.ptr(160) auto sass.ptr(48);
    gap: sass.ptr(80);
  }

  .section {
    gap: sass.ptr(64);
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .container {
    width: 100%;
    max-width: 554px;
    padding: sass.ptr(48) sass.ptr(24);
    gap: sass.ptr(48);
  }

  .section {
    gap: sass.ptr(48);
  }
}
