@use '@/ui/style/sass';

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  margin: auto;
  padding: sass.ptr(120) sass.ptr(24);
  gap: sass.ptr(24);
  background-color: sass.$white;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: sass.ptr(80);
  height: sass.ptr(80);
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: sass.ptr(16);

  .item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: sass.ptr(16);
    border: 1px solid sass.$gray_20;
    border-radius: sass.ptr(12);
    gap: sass.ptr(12);

    .text {
      display: flex;
      flex-direction: column;
      gap: sass.ptr(2);
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: sass.ptr(60);
      height: sass.ptr(60);
      border-radius: sass.ptr(8);
      color: sass.$white;

      &[data-theme='Ciety'] {
        background-color: sass.get-theme-color('Ciety', accent);
        color: sass.get-theme-color('Ciety', 'white');
      }

      &[data-theme='Marpple'] {
        background-color: sass.get-theme-color('Marpple', accent);
        color: sass.get-theme-color('MarppleShop', 'black');
      }

      &[data-theme='MarppleShop'] {
        background-color: sass.get-theme-color('MarppleShop', accent);
        color: sass.get-theme-color('MarppleShop', 'white');
      }
    }
  }
}

@include sass.htmlConditioner(false, 'pc') {
  .page {
    width: sass.ptr(512);

    .guide {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: sass.ptr(32) 0;
      text-align: center;
      gap: sass.ptr(8);
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .guide {
    width: 100%;
    padding: sass.ptr(16) 0;
    text-align: center;
  }
}
