@use '@/ui/style/sass';

.button_radio {
  display: inline-flex;

  .radio {
    inset: 0;
    cursor: pointer;
  }

  .radio_wrapper {
    display: inline-flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
    gap: sass.ptr(8);
  }

  .radio:disabled ~ .radio_wrapper {
    cursor: not-allowed;
  }
}

@include sass.htmlConditioner('Marpple') {
  .button_radio {
    .button {
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: sass.ptr(16);
      height: sass.ptr(16);
      border: 1px solid sass.$primary;
      border-radius: 99px;
      background-color: sass.$white;
    }

    .radio:checked ~ .radio_wrapper .button {
      &::after {
        content: '';
        width: sass.ptr(10);
        height: sass.ptr(10);
        border-radius: 99px;
        background-color: sass.$accent;
      }
    }
  }
}

@include sass.htmlConditioner('MarppleShop') {
  .button_radio {
    .button {
      flex-shrink: 0;
      width: sass.ptr(18);
      height: sass.ptr(18);
      transition:
        border-width 300ms ease-out,
        border-color 300ms ease-out;
      animation-duration: 300ms;
      animation-timing-function: ease-out;
      border: 1px solid sass.$gray-20;
      border-radius: 99px;
      background-color: sass.$white;
    }

    &:hover > .radio_wrapper > .button {
      border-color: sass.$accent;
    }

    .radio:checked ~ .button {
      border-width: sass.ptr(4);
      border-color: sass.$accent;
    }

    .radio:disabled ~ .button {
      border-color: sass.$gray-20;
      background-color: sass.$white;
    }

    .radio:checked ~ .radio_wrapper .button {
      border-width: sass.ptr(4);
      border-color: sass.$accent;
    }

    .radio:disabled ~ .radio_wrapper {
      .button {
        border-width: sass.ptr(4);
        border-color: sass.$gray-20;
      }
    }
  }
}

@include sass.htmlConditioner('Ciety') {
  .button_radio {
    .button {
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: sass.ptr(16);
      height: sass.ptr(16);
      transition: border-color 300ms ease-out;
      animation-duration: 300ms;
      animation-timing-function: ease-out;
      border: 1px solid sass.$black;
      border-radius: 99px;
    }

    &:hover > .radio_wrapper > .button {
      border-color: sass.$accent;
    }

    .radio:checked ~ .radio_wrapper .button {
      border-color: sass.$accent;

      &::after {
        background-color: sass.$accent;
      }
    }

    .radio:disabled ~ .radio_wrapper .button {
      border-color: sass.$gray-50;

      &::after {
        background-color: sass.$gray-50;
      }
    }

    .radio:checked ~ .radio_wrapper .button,
    .radio:disabled ~ .radio_wrapper .button {
      &::after {
        content: '';
        width: sass.ptr(8);
        height: sass.ptr(8);
        border-radius: 99px;
      }
    }

    .radio:checked ~ .button {
      border-color: sass.$accent;
    }

    .radio:disabled ~ .button {
      border-color: sass.$gray-20;
      background-color: sass.$white;
    }
  }
}
