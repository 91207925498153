@use '@/ui/style/sass';

.toast_container {
  position: fixed;
  z-index: 10001;
  bottom: calc(62px + 16px);
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  @include sass.flex(center, center, column);
}

.toast {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: sass.ptr(20);
  padding: sass.ptr(8) sass.ptr(16);
  column-gap: sass.ptr(16);
  animation-name: toast-in;
  animation-duration: 500ms;
  border: 1px solid sass.$gray_80;
  border-radius: 99px;
  background: sass.$dim_60;

  @include sass.blur40;

  &.hidden {
    animation-name: toast-out;
  }

  > .message {
    color: sass.$white;
  }

  > .close_button {
    color: sass.$gray_50;
    cursor: pointer;
  }
}

@include sass.htmlConditioner('Ciety') {
  .toast {
    padding: sass.ptr(12) sass.ptr(16);
    column-gap: sass.ptr(12);
    border-radius: sass.ptr(16);
    background: sass.$dim_60;

    > .message {
      color: sass.$black;
    }

    > .close_button {
      color: sass.$gray_50;
      cursor: pointer;
    }
  }
}

@keyframes toast-in {
  0% {
    transform: scale3d(0.8, 0.8, 0.8);
    opacity: 0;
  }

  50% {
    opacity: 0.85;
  }
}

@keyframes toast-out {
  0% {
    opacity: 0.85;
  }

  50% {
    transform: scale3d(0.8, 0.8, 0.8);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
