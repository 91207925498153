@use '@/ui/style/sass';
@use '@/ui/style/util';

@include util.mini_button;
@include util.code_animation;

@include sass.htmlConditioner(false, 'pc') {
  .modal_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(24);
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(8);

    &.hidden {
      display: none;
    }
  }

  .field:has(.input_group) {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(4);
  }

  .input_group {
    display: flex;
    gap: sass.ptr(8);

    > button {
      flex-shrink: 0;
      width: sass.ptr(160);
    }
  }

  .description_info {
    display: flex;
    align-items: center;
    color: sass.$accent;
    gap: sass.ptr(4);

    > div > span {
      flex-grow: 1;
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .modal_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(24);
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(8);

    &.hidden {
      display: none;
    }
  }

  .input_group {
    display: flex;
    gap: sass.ptr(8);

    > button {
      flex-shrink: 0;
      width: unset;
    }
  }

  .description_info {
    display: flex;
    align-items: center;
    color: sass.$accent;
    gap: sass.ptr(4);

    > span {
      align-self: flex-start;
      padding-top: sass.ptr(1.6);
    }

    > div > span {
      flex-grow: 1;
    }
  }
}
