@use '@/ui/style/sass';
@use '@/ui/style/util';

@include util.mini_button;
@include util.code_animation;

@include sass.htmlConditioner(false, 'pc') {
  .guide_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: sass.ptr(4);
  }

  .connect {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(48);

    .button_container,
    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: sass.ptr(16);
    }

    .modal_button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: sass.ptr(8);

      .divider {
        width: 1px;
        height: sass.ptr(12);
        background-color: sass.$gray_10;
      }
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .guide_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: sass.ptr(4);
  }

  .connect {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(24);

    .button_container,
    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .input_container {
      gap: sass.ptr(24);
    }

    .button_container {
      gap: sass.ptr(16);
    }

    .modal_button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: sass.ptr(8);

      .divider {
        width: 1px;
        height: sass.ptr(12);
        background-color: sass.$gray_10;
      }
    }
  }
}
