@use '@/ui/style/sass';

@include sass.htmlConditioner(false, 'pc') {
  .image_preview {
    position: relative;
    width: sass.ptr(160);
    height: sass.ptr(160);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: sass.ptr(999);
    }
  }

  .image_add {
    display: flex;
    position: absolute;
    bottom: sass.ptr(-12);
    left: 50%;
    padding: sass.ptr(4) sass.ptr(12);
    transform: translateX(-50%);
    border-radius: sass.ptr(8);
    background-color: sass.$accent;
    color: sass.$white;
  }

  .image_remove {
    display: flex;
    position: absolute;
    top: sass.ptr(8);
    right: 0;
    padding: sass.ptr(8);
    border-radius: sass.ptr(999);
    background-color: sass.$black;

    span {
      width: sass.ptr(12);
      height: sass.ptr(12);
      color: sass.$white;
    }

    &.hidden {
      display: none;
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .image_preview {
    position: relative;
    width: sass.ptr(160);
    height: sass.ptr(160);
    border-radius: sass.ptr(999);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: sass.ptr(999);
    }
  }

  .image_add {
    display: flex;
    position: absolute;
    bottom: sass.ptr(-12);
    left: 50%;
    padding: sass.ptr(4) sass.ptr(12);
    transform: translateX(-50%);
    border-radius: sass.ptr(8);
    background-color: sass.$accent;
    color: sass.$white;
  }

  .image_remove {
    display: flex;
    position: absolute;
    top: sass.ptr(8);
    right: 0;
    padding: sass.ptr(8);
    border-radius: sass.ptr(999);
    background-color: sass.$black;

    span {
      width: sass.ptr(12);
      height: sass.ptr(12);
      color: sass.$white;
    }

    &.hidden {
      display: none;
    }
  }
}
