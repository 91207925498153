@use '@/ui/style/sass';
@use '@/ui/style/util';

@include util.mini_button;
@include util.code_animation;

@include sass.htmlConditioner(false, 'pc') {
  .verify {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(80);

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: sass.ptr(32);
    }

    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: sass.ptr(8);
    }

    .guide_container {
      display: flex;
      width: 100%;
      gap: sass.ptr(4);

      .guide_icon {
        padding-top: sass.ptr(1);
      }
    }

    .button_container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: sass.ptr(16);
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .verify {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(80);

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: sass.ptr(32);
    }

    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: sass.ptr(8);
    }

    .guide_container {
      display: flex;
      width: 100%;
      gap: sass.ptr(4);

      .guide_icon {
        padding-top: sass.ptr(3);
      }
    }

    .button_container {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      align-items: center;
      width: 100%;
      padding: sass.ptr(12) sass.ptr(24);
      gap: sass.ptr(16);

      @include sass.safeAreaBottomPlus(sass.ptr(24));

      .button {
        flex-shrink: 1;
        width: 100%;
      }
    }
  }
}
