@use '@/ui/style/sass';

@include sass.htmlConditioner('Marpple', false) {
  .dropdown *,
  .dropdown *::before,
  .dropdown *::after {
    box-sizing: border-box;
  }

  .dropdown {
    display: block;
    position: relative;
    cursor: pointer;

    &.readonly {
      .dropdown_input {
        border-color: sass.$gray_80;
        color: sass.$gray_80 !important;
        cursor: default;

        &::after {
          display: none;
        }
      }
    }

    &.disabled {
      .dropdown_input {
        border-color: sass.$gray_20;
        color: sass.$gray_50 !important;
        cursor: default;

        &::after {
          border-color: sass.$gray_50;
        }
      }
    }

    &.open {
      .dropdown_input {
        border-bottom: none;
        border-radius: sass.ptr(8) sass.ptr(8) 0 0;
        color: sass.$gray_50 !important;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: sass.ptr(16);
          width: sass.ptr(11.55);
          height: sass.ptr(8.67);
          transform: translateY(-50%);
          background: url('//s3.marpple.co/files/u_3040850/2024/9/original/a6047838b1cbbf708ba50630933b05937e0c5e911.svg');
        }
      }

      .dropdown_menu {
        display: block;
        border-top: none;
      }

      .option {
        &:hover {
          background-color: sass.$gray_10;
        }

        &[data-placeholder='true'] {
          display: none;
        }
      }
    }

    .dropdown_input {
      display: block;
      width: 100%;
      padding: sass.ptr(12) sass.ptr(16) sass.ptr(12) sass.ptr(12);
      border: 1px solid sass.$black;
      border-radius: sass.ptr(8);
      background-color: sass.$white;
      color: sass.$black;

      &:focus {
        outline: none;
        color: sass.$black;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: sass.ptr(16);
        width: sass.ptr(11.55);
        height: sass.ptr(8.67);
        transform: translateY(-50%);
        background: url('//s3.marpple.co/files/u_3040850/2024/9/original/cd1d6b98ffea17586285aa1bb06084811a9942141.svg');
      }
    }

    .dropdown_menu {
      display: none;
      position: absolute;
      z-index: 100;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: sass.ptr(304);
      overflow-y: scroll;
      border: 1px solid sass.$black;
      border-radius: 0 0 sass.ptr(8) sass.ptr(8);
      background-color: sass.$white;
      scrollbar-color: sass.$gray_20 transparent;
    }

    .option {
      padding: sass.ptr(8) sass.ptr(12);
    }

    .option.option_current {
      background-color: sass.$gray_10;
    }
  }
}

@include sass.htmlConditioner('MarppleShop', false) {
  .dropdown *,
  .dropdown *::before,
  .dropdown *::after {
    box-sizing: border-box;
  }

  .dropdown {
    display: block;
    position: relative;
    cursor: pointer;

    &.readonly {
      .dropdown_input {
        border-color: sass.$gray_20;
        background-color: sass.$gray_10;
        color: sass.$black !important;
        cursor: default;

        &::after {
          display: none;
        }
      }
    }

    &.disabled {
      .dropdown_input {
        border-color: sass.$gray_20;
        background-color: sass.$gray_10;
        color: sass.$gray_50 !important;
        cursor: default;

        &::after {
          border-color: sass.$gray_50;
        }
      }
    }

    &.open {
      .dropdown_input {
        border-bottom: none;
        border-radius: sass.ptr(8) sass.ptr(8) 0 0;
        color: sass.$gray_50 !important;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: sass.ptr(16);
          width: sass.ptr(11.55);
          height: sass.ptr(8.67);
          transform: translateY(-50%);
          background: url('//s3.marpple.co/files/u_3040850/2024/9/original/a6047838b1cbbf708ba50630933b05937e0c5e911.svg');
        }
      }

      .dropdown_menu {
        display: block;
        border-top: none;
      }

      .option:hover {
        background-color: sass.$secondary;
      }
    }

    .dropdown_input {
      display: block;
      width: 100%;
      padding: sass.ptr(12) sass.ptr(16) sass.ptr(12) sass.ptr(12);
      border: 1px solid sass.$gray_20;
      border-radius: sass.ptr(8);
      background-color: sass.$white;
      color: sass.$black;

      &:focus {
        outline: none;
        color: sass.$black;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: sass.ptr(16);
        width: sass.ptr(11.55);
        height: sass.ptr(8.67);
        transform: translateY(-50%);
        background: url('//s3.marpple.co/files/u_3040850/2024/9/original/cd1d6b98ffea17586285aa1bb06084811a9942141.svg');
      }
    }

    .dropdown_menu {
      display: none;
      position: absolute;
      z-index: 100;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: sass.ptr(304);
      overflow-y: scroll;
      border: 1px solid sass.$gray_20;
      border-radius: 0 0 sass.ptr(8) sass.ptr(8);
      background-color: sass.$white;
      scrollbar-color: sass.$gray_20 transparent;
    }

    .option {
      padding: sass.ptr(8) sass.ptr(12);
    }

    .option.option_current {
      background-color: sass.$secondary;
    }
  }
}

@include sass.htmlConditioner('Ciety', false) {
  .dropdown *,
  .dropdown *::before,
  .dropdown *::after {
    box-sizing: border-box;
  }

  .dropdown {
    display: block;
    position: relative;
    cursor: pointer;

    &.readonly {
      .dropdown_input {
        border-color: sass.$gray_5;
        background-color: sass.$gray_2_5;
        color: sass.$gray_80 !important;
        cursor: default;

        &::after {
          display: none;
        }
      }
    }

    &.disabled {
      .dropdown_input {
        border-color: sass.$gray_5;
        background-color: sass.$gray_2_5;
        color: sass.$gray_50 !important;
        cursor: default;

        &::after {
          transform: translateY(-50%) rotate(0deg);
          background: url('//s3.marpple.co/files/u_2283830/2025/4/original/d2b7c3fd7b8c53bff9939a9fb56af9c898ebc3e51.svg');
        }
      }
    }

    &.open {
      .dropdown_input {
        border-bottom: none;
        border-radius: sass.ptr(8) sass.ptr(8) 0 0;
        color: sass.$gray_50 !important;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: sass.ptr(16);
          width: sass.ptr(24);
          height: sass.ptr(24);
          transform: translateY(-50%);
          background: url('//s3.marpple.co/files/u_2283830/2025/4/original/915433948195d737f81c97b96501c6a15da15aff2.svg');
        }
      }

      .dropdown_menu {
        display: block;
        border-top: none;
      }

      .option:hover {
        background-color: sass.$gray_10;
      }
    }

    .dropdown_input {
      display: block;
      width: 100%;
      padding: sass.ptr(12) sass.ptr(16) sass.ptr(12) sass.ptr(12);
      border: 1px solid sass.$gray_20;
      border-radius: sass.ptr(8);
      background-color: sass.$gray_5;
      color: sass.$black;

      &:focus {
        outline: none;
        color: sass.$black;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: sass.ptr(16);
        width: sass.ptr(24);
        height: sass.ptr(24);
        transform: translateY(-50%) rotate(180deg);
        background: url('//s3.marpple.co/files/u_2283830/2025/4/original/915433948195d737f81c97b96501c6a15da15aff2.svg');
      }
    }

    .dropdown_menu {
      display: none;
      position: absolute;
      z-index: 100;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: sass.ptr(304);
      overflow-y: scroll;
      border: 1px solid sass.$gray_20;
      border-radius: 0 0 sass.ptr(8) sass.ptr(8);
      background-color: sass.$white;
      scrollbar-color: sass.$gray_20 transparent;
    }

    .option {
      padding: sass.ptr(8) sass.ptr(12);
    }

    .option.option_current {
      background-color: sass.$secondary;
    }
  }
}
