@use '@/ui/style/sass';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: sass.ptr(60);
  padding: 0 sass.ptr(16);

  > :last-child,
  > :first-child {
    flex-basis: sass.ptr(40);
    width: sass.ptr(24);
    height: sass.ptr(24);
  }
}

@include sass.htmlConditioner('Ciety', 'mo', 'app') {
  .header {
    > :first-child {
      height: 0;
      overflow: hidden;
      pointer-events: none;
    }
  }
}

html[app_name='Ciety'] {
  .header {
    > :first-child {
      height: 0;
      overflow: hidden;
      pointer-events: none;
    }
  }
}
