@use '@/ui/style/sass';

.color {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 12px;
  border-radius: 12px;

  &.black {
    background-color: sass.$black;
    color: sass.$white;
  }

  &.white {
    background-color: sass.$white;
    color: sass.$black;
  }

  &.primary {
    background-color: sass.$primary;
    color: sass.$white;
  }

  &.secondary {
    background-color: sass.$secondary;
    color: sass.$primary;
  }

  &.tertiary {
    background-color: sass.$tertiary;
    color: sass.$white;
  }

  &.success {
    background-color: sass.$success;
    color: sass.$white;
  }

  &.error {
    background-color: sass.$error;
    color: sass.$white;
  }

  &.accent {
    background-color: sass.$accent;
    color: sass.$white;
  }

  &.gray_80 {
    background-color: sass.$gray_80;
    color: sass.$white;
  }

  &.gray_50 {
    background-color: sass.$gray_50;
    color: sass.$white;
  }

  &.gray_20 {
    background-color: sass.$gray_20;
    color: sass.$gray_80;
  }

  &.gray_10 {
    background-color: sass.$gray_10;
    color: sass.$gray_80;
  }

  &.gray_5 {
    background-color: sass.$gray_5;
    color: sass.$black;
  }

  &.gray_2_5 {
    background-color: sass.$gray_2_5;
    color: sass.$black;
  }
}
