@use '@/ui/style/sass';

.popup {
  display: none;
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.open {
    @include sass.flex(center, center);
  }

  &.backdrop {
    transition: opacity 0.35s;
    opacity: 0;
    background: sass.$dim_60;

    &.open {
      z-index: 10001;
      opacity: 1;
    }
  }
}

.popup__window {
  width: 343px;
  min-width: sass.ptr(288);
  padding: sass.ptr(36) sass.ptr(24) sass.ptr(24);
  transform: translateY(50%);
  border-radius: sass.ptr(20);
  background-color: sass.$white;
  gap: sass.ptr(16);
  animation-fill-mode: forwards;

  &.animate {
    animation-duration: 0.35s;
  }

  &.slide_in {
    animation-name: slide-in;
  }

  &.slide_out {
    animation-name: slide-out;
  }

  @include sass.flex(center, center, column);
}

.popup__header {
  @include sass.flex(center, center);
}

.popup__content {
  @include sass.flex(center, center);

  text-align: center;

  &.content_full {
    width: 100%;
  }
}

.popup__footer {
  @include sass.flex(center, center);

  gap: sass.ptr(12);
  flex: 1 0 0;
  width: 100%;
}

@include sass.htmlConditioner('Marpple') {
  .popup__window {
    width: 360px;
    padding: sass.ptr(30);
  }

  .popup__footer {
    gap: sass.ptr(8);
  }
}

@include sass.htmlConditioner('MarppleShop') {
  .popup__content {
    margin-bottom: sass.ptr(16);
  }
}

@include sass.htmlConditioner('Ciety') {
  .popup__window {
    background-color: sass.$gray_2_5;
  }

  .popup__content {
    margin-bottom: sass.ptr(16);
  }
}

@keyframes slide-in {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(50%);
    opacity: 0;
  }
}
