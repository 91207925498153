@use '@/ui/style/sass';

@include sass.htmlConditioner('Marpple', 'pc') {
  .t1,
  .t2,
  .t3,
  .t4,
  .h1 {
    @include sass.base(sass.ptr(20), 140%, sass.$font-weight-bold);
  }

  .h2,
  .h3 {
    @include sass.base(sass.ptr(20), 140%, sass.$font-weight-bold);
  }

  .p1 {
    @include sass.base(sass.ptr(16), 150%, sass.$font-weight-normal);
  }

  .p2 {
    @include sass.base(sass.ptr(14), 140%, sass.$font-weight-normal);
  }

  .p3 {
    @include sass.base(sass.ptr(12), 140%, sass.$font-weight-normal);
  }

  .c1 {
    @include sass.base(sass.ptr(10), 100%, sass.$font-weight-normal);
  }
}
@include sass.htmlConditioner('Marpple', 'mo') {
  .t1,
  .t2,
  .t3,
  .t4,
  .h1,
  .h2 {
    @include sass.base(sass.mptr(20), 140%, sass.$font-weight-bold);
  }

  .h3 {
    @include sass.base(sass.mptr(16), 140%, sass.$font-weight-bold);
  }

  .p1,
  .p2 {
    @include sass.base(sass.mptr(14), 140%, sass.$font-weight-normal);
  }

  .p3 {
    @include sass.base(sass.mptr(12), 140%, sass.$font-weight-normal);
  }

  .c1 {
    @include sass.base(sass.mptr(10), 100%, sass.$font-weight-normal);
  }
}
@include sass.htmlConditioner('MarppleShop', 'pc') {
  .t1 {
    @include sass.base(sass.ptr(48), sass.ptr(60), sass.$font-weight-bold);
  }

  .t2 {
    @include sass.base(sass.ptr(36), sass.ptr(50), sass.$font-weight-bold);
  }

  .t3 {
    @include sass.base(sass.ptr(32), sass.ptr(45), sass.$font-weight-bold);
  }

  .t4 {
    @include sass.base(sass.ptr(28), sass.ptr(39), sass.$font-weight-bold);
  }

  .h1 {
    @include sass.base(sass.ptr(24), sass.ptr(34), sass.$font-weight-bold);
  }

  .h2,
  .h3 {
    @include sass.base(sass.ptr(20), sass.ptr(28), sass.$font-weight-bold);
  }

  .p1 {
    @include sass.base(sass.ptr(16), sass.ptr(24), sass.$font-weight-normal);
  }

  .p2 {
    @include sass.base(sass.ptr(14), sass.ptr(22), sass.$font-weight-normal);
  }

  .p3 {
    @include sass.base(sass.ptr(12), sass.ptr(17), sass.$font-weight-normal);
  }

  .c1 {
    @include sass.base(sass.ptr(10), sass.ptr(14), sass.$font-weight-normal);
  }

  .en {
    font-family: sass.$font-family-en;
  }
}
@include sass.htmlConditioner('MarppleShop', 'mo') {
  .t1 {
    @include sass.base(sass.ptr(40), sass.ptr(56), sass.$font-weight-bold);
  }

  .t2 {
    @include sass.base(sass.ptr(32), sass.ptr(45), sass.$font-weight-bold);
  }

  .t3 {
    @include sass.base(sass.ptr(28), sass.ptr(39), sass.$font-weight-bold);
  }

  .t4 {
    @include sass.base(sass.ptr(24), sass.ptr(34), sass.$font-weight-bold);
  }

  .h1,
  .h2 {
    @include sass.base(sass.mptr(20), sass.mptr(28), sass.$font-weight-bold);
  }

  .h3 {
    @include sass.base(sass.mptr(16), sass.mptr(24), sass.$font-weight-bold);
  }

  .p1,
  .p2 {
    @include sass.base(sass.mptr(14), sass.mptr(22), sass.$font-weight-normal);
  }

  .p3 {
    @include sass.base(sass.mptr(12), sass.mptr(17), sass.$font-weight-normal);
  }

  .c1 {
    @include sass.base(sass.mptr(10), sass.mptr(14), sass.$font-weight-normal);
  }

  .en {
    font-family: sass.$font-family-en;
  }
}

.typo {
  word-break: keep-all;
}

@include sass.htmlConditioner(false, false, false, 'jp') {
  .typo {
    word-break: break-all;
  }
}

.underline {
  text-decoration: underline !important;
}

.bold {
  font-weight: sass.$font-weight-bold !important;
}

.medium {
  font-weight: sass.$font-weight-normal !important;
}

.regular {
  font-weight: sass.$font-weight-regular !important;
}

@include sass.htmlConditioner('Ciety', 'pc') {
  .t1,
  .t2,
  .t3,
  .t4 {
    &.bold {
      font-weight: sass.$font-weight-bold !important;
    }

    @include sass.base(sass.mptr(32), 130%, sass.$font-weight-bold);
  }

  .h1 {
    @include sass.base(sass.ptr(24), 130%, sass.$font-weight-normal);
  }

  .h2,
  .h3 {
    @include sass.base(sass.ptr(20), 130%, sass.$font-weight-normal);
  }

  .p1 {
    @include sass.base(sass.ptr(16), 150%, sass.$font-weight-regular);
  }

  .p2 {
    @include sass.base(sass.ptr(14), 150%, sass.$font-weight-regular);
  }

  .p3 {
    @include sass.base(sass.ptr(12), 150%, sass.$font-weight-regular);
  }

  .c1 {
    @include sass.base(sass.ptr(10), 150%, sass.$font-weight-normal);
  }

  .en {
    font-family: sass.$font-family-en;
  }

  .bold {
    font-weight: sass.$font-weight-normal !important;
  }

  .medium {
    font-weight: sass.$font-weight-regular !important;
  }

  .regular {
    font-weight: sass.$font-weight-regular !important;
  }
}
@include sass.htmlConditioner('Ciety', 'mo') {
  .t1,
  .t2 {
    &.bold {
      font-weight: sass.$font-weight-bold !important;
    }

    @include sass.base(sass.mptr(32), 130%, sass.$font-weight-bold);
  }

  .t3,
  .t4 {
    @include sass.base(sass.mptr(24), 130%, sass.$font-weight-bold);
  }

  .h1,
  .h2 {
    @include sass.base(sass.mptr(20), 130%, sass.$font-weight-regular);
  }

  .h3 {
    @include sass.base(sass.mptr(16), 130%, sass.$font-weight-regular);
  }

  .p1,
  .p2 {
    @include sass.base(sass.mptr(14), 130%, sass.$font-weight-regular);
  }

  .p3 {
    @include sass.base(sass.mptr(12), 130%, sass.$font-weight-regular);
  }

  .c1 {
    @include sass.base(sass.mptr(10), 130%, sass.$font-weight-normal);
  }

  .en {
    font-family: sass.$font-family-en;
  }

  .bold {
    font-weight: sass.$font-weight-normal !important;
  }

  .medium {
    font-weight: sass.$font-weight-regular !important;
  }

  .regular {
    font-weight: sass.$font-weight-regular !important;
  }
}

.black {
  color: sass.$black;
}

.white {
  color: sass.$white;
}

.primary {
  color: sass.$primary;
}

.secondary {
  color: sass.$secondary;
}

.tertiary {
  color: sass.$tertiary;
}

.success {
  color: sass.$success;
}

.error {
  color: sass.$error;
}

.accent {
  color: sass.$accent;
}

.gray_80 {
  color: sass.$gray_80;
}

.gray_50 {
  color: sass.$gray_50;
}

.gray_20 {
  color: sass.$gray_20;
}

.gray_10 {
  color: sass.$gray_10;
}

.gray_5 {
  color: sass.$gray_5;
}

.gray_2_5 {
  color: sass.$gray_2_5;
}
