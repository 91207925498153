@use '@/ui/style/sass';

.button_sns_icon {
  @include sass.flex(center, center);

  gap: sass.ptr(8);
  position: relative;
  width: fit-content;
  min-width: sass.ptr(48);
  height: sass.ptr(48);
  padding: sass.ptr(10);
  border-radius: sass.ptr(999);

  &.kakao {
    background-color: #fee500;
  }

  &.naver {
    background-color: #03c75a;
    color: sass.$white;
  }

  &.google {
    border: 1px solid sass.$gray-10;
    background-color: sass.$white;
  }

  &.apple {
    background-color: sass.$black;
    color: sass.$white;
  }

  &.facebook {
    background: #0866ff;
    color: sass.$white;
  }

  &.facebook_inactive,
  &.google_inactive,
  &.naver_inactive,
  &.apple_inactive,
  &.kakao_inactive {
    background-color: #e8e8e8;
    color: sass.$white;
  }

  &.google_inactive {
    border: none;
  }

  .unlink_icon {
    display: inline-flex;
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    font-size: sass.ptr(16);
  }

  .label {
    display: inline-flex;
  }

  &[data-button-type='label'] {
    padding: sass.ptr(8) sass.ptr(16);
  }

  &[data-button-type='fullsize_center'],
  &[data-button-type='fullsize'] {
    width: 100%;
    height: sass.ptr(56);
    padding: sass.ptr(16) sass.ptr(24);
    border-radius: sass.ptr(8);
  }

  &[data-button-type='fullsize'] {
    .label {
      flex: 1 1 100%;
      align-items: center;
      justify-content: center;
    }
  }

  &[data-button-type='label'],
  &[data-button-type='fullsize_center'],
  &[data-button-type='fullsize'] {
    &.google {
      background-color: sass.$gray-10;
    }
  }

  .w-28 {
    width: sass.ptr(28);
  }
}

@include sass.htmlConditioner('Marpple') {
  .button_sns_icon {
    &[data-button-type='fullsize_center'],
    &[data-button-type='fullsize'] {
      border-radius: sass.ptr(999);
    }
  }
}

@include sass.htmlConditioner('Ciety') {
  .button_sns_icon {
    .label {
      color: sass.$white;
    }

    &.google,
    &.apple {
      background-color: #fff;
      color: #000;
    }

    &.facebook_inactive,
    &.google_inactive,
    &.naver_inactive,
    &.apple_inactive,
    &.kakao_inactive {
      background-color: sass.$dk_95;
      color: sass.$dk_80;
    }

    &.google_inactive {
      border: none;
    }

    &[data-button-type='fullsize_center'],
    &[data-button-type='fullsize'] {
      border-radius: sass.ptr(999);
    }
  }
}
