@charset "utf-8";

@font-face {
  font-family: 'Neue Haas Unica Pro';
  font-display: fallback;
  font-weight: 700;
  src:
    local('Neue Haas Unica Pro'),
    url('woff2/NeueHaasUnicaPro-Bold.woff2') format('woff2'),
    url('woff/NeueHaasUnicaPro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Unica Pro';
  font-display: fallback;
  font-weight: 500;
  src:
    local('Neue Haas Unica Pro'),
    url('woff2/NeueHaasUnicaPro-Medium.woff2') format('woff2'),
    url('woff/NeueHaasUnicaPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Unica Pro';
  font-display: fallback;
  font-weight: 400;
  src:
    local('Neue Haas Unica Pro'),
    url('woff2/NeueHaasUnicaPro-Regular.woff2') format('woff2'),
    url('woff/NeueHaasUnicaPro-Regular.woff') format('woff');
}
