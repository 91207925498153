@use '@/ui/style/sass';

@keyframes fade-out {
  0% {
    display: inline-flex;
    visibility: visible;
    opacity: 1;
  }

  100% {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: stretch;
  width: 100%;
  gap: sass.ptr(8);

  .input {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: sass.ptr(48);
    padding-right: sass.ptr(16);
    padding-bottom: 1px;
    gap: sass.ptr(8);

    .input_base {
      flex: 1 1 0;
      width: 100%;
      padding: sass.ptr(12) 0;
      caret-color: sass.$primary;
      background-color: transparent;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      &::placeholder {
        color: sass.$gray_50;
      }
    }

    .input_controller {
      display: inline-flex;
      align-items: center;
      color: sass.$gray_50;

      &[data-end] {
        color: sass.$accent;
      }

      .button_container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: sass.ptr(24);
        height: sass.ptr(24);
      }

      .reset {
        display: none;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .input_helper {
    display: none;
    flex-wrap: wrap;
    color: sass.$gray_50;

    &[data-enable='true'] {
      display: inline-flex;
      gap: sass.ptr(8);
    }

    .text {
      display: inline-flex;
      align-items: center;
      color: sass.$gray_50;
      gap: sass.ptr(4);

      &[data-valid='false'] {
        color: sass.$error;
      }

      &[data-valid='true'] {
        color: sass.$success;
      }
    }
  }

  @include sass.allThemify {
    &[data-focus='true'][data-has_value]:not([data-has_value='false']) {
      .input {
        .input_controller {
          .reset {
            display: inline-flex;
            visibility: visible;
            animation: fade-in 200ms ease-out forwards;
            pointer-events: unset;
          }
        }
      }
    }

    &[data-has_value='false']:not([data-init='true']),
    &[data-focus='false']:not([data-init='true']) {
      .input {
        .input_controller {
          .reset {
            animation: fade-out 200ms ease-out forwards;
          }
        }
      }
    }

    &[data-valid='false'] {
      .input {
        border-bottom-color: sass.$error;

        &:focus-within {
          border-bottom-color: sass.$error;
        }

        .input_base {
          caret-color: sass.$error;
        }

        .input_controller {
          &[data-end] {
            color: sass.$error;
          }
        }
      }

      .input_helper {
        color: sass.$error;

        &[data-type='always'] {
          color: sass.$gray_50;
        }
      }
    }

    .input:has(+ [data-type='always']) {
      border-bottom-color: sass.$gray_50;
    }
  }

  @include sass.conditionThemify('MarppleShop') {
    .input {
      transition: border-bottom 200ms ease-out;
      border-bottom: 1px solid;
      border-bottom-color: sass.$gray_20;
    }

    .input_helper {
      .text {
        transition: color 100ms ease-out;
      }
    }

    &[data-focus='true'] {
      .input {
        border-bottom-color: sass.$primary;
      }
    }

    &[data-disabled='true'] {
      .input {
        background-color: sass.$gray_10;
        color: sass.$gray_50;
      }
    }

    &[data-readonly='true'] {
      .input {
        background-color: sass.$gray_10;
      }
    }
  }

  @include sass.conditionThemify('Marpple') {
    .input {
      border-bottom: 1px solid;
      border-bottom-color: sass.$primary;
    }

    &[data-disabled='true'] {
      .input {
        border-bottom-color: sass.$gray_20;
        color: sass.$gray_20;
      }
    }

    &[data-readonly='true'] {
      .input {
        color: sass.$gray_80;
      }
    }
  }

  @include sass.conditionThemify('Ciety') {
    .input {
      padding-left: sass.ptr(16);
      transition: border-color 200ms ease-out;
      border: 1px solid sass.$gray_5;
      border-radius: sass.ptr(8);
      background-color: sass.$gray_5;
    }

    .input_helper {
      .text {
        transition: color 100ms ease-out;
      }
    }

    &[data-focus='true'] {
      .input {
        border-color: sass.$primary;
      }
    }

    &[data-disabled='true'] {
      .input {
        background-color: sass.$gray_2_5;
        color: sass.$gray_50;
      }
    }

    &[data-readonly='true'] {
      .input {
        background-color: sass.$gray_2_5;
        color: sass.$gray_80;
      }
    }

    &[data-valid='false'] {
      .input {
        border-color: sass.$error;

        &:focus-within {
          border-color: sass.$error;
        }

        .input_base {
          caret-color: sass.$error;
        }

        .input_controller {
          &[data-end] {
            color: sass.$error;
          }
        }
      }

      .input_helper {
        color: sass.$error;

        &[data-type='always'] {
          color: sass.$gray_50;
        }
      }
    }

    .input:has(+ [data-type='always']) {
      border-color: sass.$gray_50;
    }
  }
}
