@use '@/ui/style/sass';

@include sass.htmlConditioner(false, 'pc') {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(24);
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: sass.ptr(4);
  }

  .radios {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(16);
    padding: sass.ptr(8);

    .radio {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-width: 100%;
      gap: sass.ptr(4) sass.ptr(16);
    }

    .sns {
      display: flex;
      align-items: center;
      gap: sass.ptr(8);
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(24);
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: sass.ptr(4);
  }

  .radios {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(16);
    padding: sass.ptr(8);

    .radio {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-width: 100%;
      gap: sass.ptr(4) sass.ptr(16);
    }

    .sns {
      display: flex;
      align-items: center;
      gap: sass.ptr(8);
    }
  }
}
