@use '@/ui/style/sass';

.field_description_info {
  display: flex;
  align-items: center;
  margin-top: sass.ptr(8);
  color: sass.$accent;
  gap: sass.ptr(4);
}

html[device='mo'] {
  .field_description_info > span {
    padding-top: sass.ptr(2);
  }
}
