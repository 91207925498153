@use '@/ui/style/sass';

@include sass.htmlConditioner(false, 'pc') {
  .signup {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(32);

    .info_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .agreement_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: sass.ptr(16) 0;

      .all {
        width: 100%;
        padding-bottom: sass.ptr(12);
        border-bottom: 1px solid sass.$gray_20;
      }

      .item_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: sass.ptr(12);
        gap: sass.ptr(12);
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: sass.ptr(8);

        .see_more {
          flex-shrink: 0;
        }
      }
    }

    .button_container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: sass.ptr(16);

      .button {
        flex: 0 1 0;
        min-width: sass.ptr(160);
      }

      .submit {
        flex: 1 1 0;
      }
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .signup {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(32);

    .info_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .agreement_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: sass.ptr(16) 0;

      .all {
        width: 100%;
        padding-bottom: sass.ptr(16);
        border-bottom: 1px solid sass.$gray_20;
      }

      .item_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: sass.ptr(16);
        gap: sass.ptr(8);
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: sass.ptr(8);

        .see_more {
          flex-shrink: 0;
        }
      }
    }

    .button_container {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      align-items: center;
      width: 100%;
      padding: sass.ptr(16);
      border-top: 1px solid sass.$gray_5;
      background-color: sass.$white;
      gap: sass.ptr(8);

      @include sass.safeAreaBottomPlus(sass.ptr(16));

      > button {
        flex: 1 0 0;
      }
    }
  }
}
