@use '@/ui/style/sass';
@use '@/ui/style/util';

@include util.mini_button;
@include util.code_animation;

@include sass.htmlConditioner('Marpple') {
  .email_container {
    background-color: sass.$gray_10;
  }
}

@include sass.htmlConditioner('MarppleShop') {
  .email_container {
    background-color: sass.$secondary;
  }
}

@include sass.htmlConditioner('Ciety') {
  .email_container {
    background-color: sass.$gray_5;
  }
}

@include sass.htmlConditioner(false, 'pc') {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(24);
    text-align: center;

    .email_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: sass.ptr(10) sass.ptr(16);
      border-radius: sass.ptr(8);
      gap: sass.ptr(16);
    }

    .sns_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: sass.ptr(16);
      gap: sass.ptr(16);
    }

    .sns_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: sass.ptr(32);

      .sns {
        display: flex;
        align-self: center;
        gap: sass.ptr(8);
      }
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(24);
    padding: sass.ptr(24) 0;
    text-align: center;

    .email_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: sass.ptr(10) sass.ptr(16);
      border-radius: sass.ptr(8);
      gap: sass.ptr(16);
    }

    .sns_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: sass.ptr(16);
      border-radius: sass.ptr(8);
      background-color: sass.$gray_10;
      gap: sass.ptr(16);

      .guide {
        width: 100%;
        padding-bottom: sass.ptr(8);
        text-align: center;
      }
    }

    .sns_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: sass.ptr(32);

      .sns {
        display: flex;
        align-self: center;
        gap: sass.ptr(8);
      }
    }
  }
}
