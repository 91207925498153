@use '@/ui/style/sass';

@include sass.htmlConditioner(false, 'pc') {
  .signin {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(32);

    .button_container,
    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: sass.ptr(16);
    }

    .modal_button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: sass.ptr(8);

      .divider {
        width: 1px;
        height: sass.ptr(12);
        background-color: sass.$gray_10;
      }
    }
  }

  .sns_signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: sass.ptr(16);

    .button_container {
      display: flex;
      gap: sass.ptr(12);
    }
  }

  .signup {
    display: flex;
    gap: sass.ptr(16);
  }

  .theme_image {
    width: 41.6667%;
    max-width: 800px;
    height: 100%;
    min-height: 100vh;
    transition:
      width 300ms ease-out,
      opacity 300ms ease-out;
    object-fit: cover;
    object-position: center center;

    @media (width <= 1440px) {
      width: 0;
      opacity: 0;
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .signin {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(24);

    .button_container,
    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: sass.ptr(16);
    }

    .modal_button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: sass.ptr(8);

      .divider {
        width: 1px;
        height: sass.ptr(12);
        background-color: sass.$gray_10;
      }
    }
  }

  .sns_signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: sass.ptr(16);

    .button_container {
      display: flex;
      gap: sass.ptr(12);
    }
  }

  .signup {
    display: flex;
    gap: sass.ptr(16);
  }
}

.button_wrapper {
  position: relative;
  width: fit-content;
  height: sass.ptr(48);
}

.ciety_password,
.current_sns {
  display: flex;
  position: absolute;
  bottom: sass.ptr(-4);
  left: 50%;
  flex-direction: column;
  align-items: center;
  transform: translate3d(-50%, 100%, 0);
  pointer-events: none;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: sass.ptr(36);
    margin-top: -1px;
    padding: sass.ptr(4) sass.ptr(16);
    border-radius: sass.ptr(999);
    white-space: nowrap;

    @include sass.conditionThemify('Ciety') {
      background-color: sass.$accent;
      color: sass.$gray_2_5;
    }

    @include sass.conditionThemify('Marpple') {
      background-color: sass.$accent;
      color: sass.$white;
    }

    @include sass.conditionThemify('MarppleShop') {
      background-color: sass.$secondary;
      color: sass.$primary;
    }
  }
}

.ciety_password {
  left: 50%;
  transform: translate3d(-65%, 100%, 0);

  .pin {
    margin-left: 30%;
  }
}

.google_button {
  position: absolute;
  z-index: 1;
  inset: 0;
  opacity: 0;
}
