@use '@/ui/style/sass';

.terms_page {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: sass.ptr(96) auto;
  padding: 0 sass.ptr(32);
  gap: sass.ptr(32);

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: sass.ptr(48);

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: sass.ptr(32);
    }

    .title {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: sass.ptr(16);
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .terms_page {
    margin: sass.ptr(48) auto;
    padding: 0;
    gap: sass.ptr(32);

    .header {
      gap: sass.ptr(32);

      .title {
        padding: 0 sass.ptr(16);
      }
    }
  }
}
