@mixin code_animation {
  @keyframes fadeout {
    0% {
      transform: scaleY(1);
      opacity: 1;
    }

    40% {
      transform: scaleY(0.9);
      opacity: 0.8;
    }

    80% {
      transform: scaleY(0.5);
      opacity: 0.3;
    }

    100% {
      display: none;
      visibility: hidden;
      height: 0;
      transform: scaleY(0);
      opacity: 0;
    }
  }
  @keyframes fadein {
    0% {
      transform: scaleY(0);
      opacity: 0;
    }

    20% {
      transform: scaleY(0.5);
      opacity: 0.3;
    }

    60% {
      transform: scaleY(0.8);
      opacity: 0.7;
    }

    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  .code {
    transform-origin: top center;
    opacity: 0;

    &.disabled {
      display: none;
    }

    &.fade_out {
      animation: fadeout 300ms ease-in-out forwards;
    }

    &.fade_in {
      animation: fadein 300ms ease-in-out forwards;
    }
  }
}
