@use '@/ui/style/sass';
@use '@/ui/style/util';

@include util.mini_button;
@include util.code_animation;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: sass.ptr(24);

  &.input_container {
    gap: sass.ptr(8);
  }
}
