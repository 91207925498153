@use '@/ui/style/sass';

@keyframes slide-in {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(50%);
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    position: absolute;
    top: 0;
    opacity: 0;
  }

  100% {
    position: absolute;
    top: 0;
    opacity: 1;
  }
}

.modal_container {
  display: flex;
  position: fixed;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  inset: 0;

  .backdrop {
    position: absolute;
    z-index: 1;
    transition: opacity 200ms;
    opacity: 0;
    background: sass.$dim-60;
    inset: 0;

    &.open {
      opacity: 1;
    }
  }

  .modal {
    z-index: 2;
    overflow: hidden;
    transform: translateY(50%);
    opacity: 0;
    background-color: sass.$white;
    animation-fill-mode: forwards;

    .body_container {
      display: flex;
      flex: 1;
      width: 100%;

      .body_wrapper {
        display: flex;
        position: relative;
        flex: 1;
        width: 100%;
        overflow: hidden;
        transition: height 400ms ease-out;
      }

      .body {
        flex: 1;
        width: 100%;

        &.fade_out {
          animation: fadeout 400ms ease-out forwards;
        }

        &.fade_in {
          animation: fadein 400ms ease-out forwards;
        }
      }
    }

    &.animate {
      animation-duration: 0.35s;
    }

    &.slide_in {
      animation-name: slide-in;
    }

    &.slide_out {
      animation-name: slide-out;
    }
  }

  &.open {
    display: flex;
  }
}

@include sass.htmlConditioner('Marpple', 'pc') {
  .modal {
    display: flex;
    flex-direction: column;
    width: sass.ptr(500);
    padding: sass.ptr(32);
    border-radius: sass.ptr(20);
    background-color: sass.$white;
    gap: sass.ptr(48);

    .header {
      display: flex;
      gap: sass.ptr(8);
      align-items: center;
      justify-content: space-between;
    }

    .footer {
      display: flex;
      gap: sass.ptr(8);
    }
  }
}

@include sass.htmlConditioner('Marpple', 'mo') {
  .modal {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    background-color: sass.$white;
    inset: 0;

    .header {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-end;
      height: sass.ptr(60);
      padding: sass.ptr(18) sass.ptr(16);
      gap: sass.ptr(8);

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    .body_container {
      padding: sass.ptr(24) sass.ptr(16);
    }

    .footer {
      display: flex;
      gap: sass.ptr(8);
      padding: sass.ptr(8) sass.ptr(16);
    }
  }
}

@include sass.htmlConditioner('MarppleShop', 'pc') {
  .modal {
    display: flex;
    flex-direction: column;
    width: sass.ptr(500);
    border-radius: sass.ptr(16);
    background-color: sass.$white;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: sass.ptr(16);
      border-bottom: 1px solid sass.$gray_10;
      gap: sass.ptr(8);
    }

    .body_container {
      padding: sass.ptr(16);
    }

    .footer {
      display: flex;
      gap: sass.ptr(16);
      padding: sass.ptr(16);
      border-top: 1px solid sass.$gray_10;
    }
  }
}

@include sass.htmlConditioner('MarppleShop', 'mo') {
  .modal {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    background-color: sass.$white;
    inset: 0;

    .header {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-end;
      height: sass.ptr(60);
      padding: sass.ptr(18) sass.ptr(16);
      gap: sass.ptr(8);

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    .body_container {
      padding: sass.ptr(24);
    }

    .footer {
      display: flex;
      gap: sass.ptr(8);
      padding: sass.ptr(16);

      @include sass.safeAreaBottomPlus(sass.ptr(16));
    }
  }
}

@include sass.htmlConditioner('Ciety', 'pc') {
  .modal {
    display: flex;
    flex-direction: column;
    width: sass.ptr(480);
    padding: sass.ptr(20);
    border-radius: sass.ptr(20);
    background-color: sass.$white;
    gap: sass.ptr(20);

    .header {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-end;

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    .footer {
      display: flex;
      margin-top: sass.ptr(20);
      gap: sass.ptr(12);
    }
  }
}

@include sass.htmlConditioner('Ciety', 'mo') {
  .modal {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    background-color: sass.$white;
    inset: 0;

    @include sass.safeAreaTop;

    .header {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-end;
      height: sass.ptr(60);
      padding: sass.ptr(18) sass.ptr(16);
      gap: sass.ptr(8);

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    .body_container {
      padding: sass.ptr(24) sass.ptr(16);
    }

    .footer {
      display: flex;
      gap: sass.ptr(12);
      padding: sass.ptr(8) sass.ptr(16);

      @include sass.safeAreaBottomPlus(sass.ptr(16));
    }
  }
}
