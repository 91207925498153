@use '@/ui/style/sass';

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    z-index: 1;
    opacity: 0;
  }

  100% {
    z-index: 1;
    opacity: 1;
  }
}

@include sass.htmlConditioner(false, 'pc') {
  .layout {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    min-height: 100dvh;
    background-color: sass.$white;

    .header_container,
    .footer_container {
      display: flex;
      width: 100%;
      min-width: sass.$pc-layout-body-min-width;
    }

    .body_container {
      display: flex;
      position: relative;
      flex: 1;
      width: 100%;

      .body {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        min-width: sass.$pc-layout-body-min-width;
        max-width: sass.$pc-layout-body-max-width;
        margin: 0 auto;

        &.full_width {
          max-width: unset;
        }

        &.fade_out {
          position: absolute;
          top: 0;
          animation: fadeout 400ms ease-out forwards;
        }

        &.fade_in {
          position: absolute;
          top: 0;
          animation: fadein 400ms ease-out forwards;
        }
      }
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .layout {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100dvh;
    background-color: sass.$white;

    .header_container,
    .footer_container {
      display: flex;
      width: 100%;
    }

    .body_container {
      display: flex;
      position: relative;
      flex: 1;
      width: 100%;

      .body {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;

        &.fade_out {
          position: absolute;
          top: 0;
          animation: fadeout 400ms ease-out forwards;
        }

        &.fade_in {
          position: absolute;
          top: 0;
          animation: fadein 400ms ease-out forwards;
        }
      }
    }
  }
}

@include sass.htmlConditioner('Ciety', 'mo', 'app') {
  .layout {
    @include sass.safeAreaTop;
  }
}

html[app_name='Ciety'] {
  .layout {
    @include sass.safeAreaTop;
  }
}
