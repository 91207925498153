@use '@/ui/style/sass';

.button_checkbox {
  display: inline-flex;
  position: relative;

  .checkbox {
    position: absolute;
    inset: 0;
    cursor: pointer;
  }

  .button_wrapper {
    display: inline-flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
    gap: sass.ptr(8);
  }

  .checkbox:disabled {
    cursor: not-allowed;
  }

  .checkbox:disabled ~ .button_wrapper {
    cursor: not-allowed;
  }

  .checkbox:checked ~ .button_wrapper .button > .check {
    opacity: 1;
  }
}

@include sass.htmlConditioner('Marpple') {
  .button_checkbox {
    .button_wrapper {
      .button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: sass.ptr(16);
        height: sass.ptr(16);
        border: 1px solid sass.$primary;
        border-radius: 4px;
        background-color: sass.$white;
      }

      .check {
        display: inline-flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        opacity: 0;
        color: sass.$primary;
      }
    }

    .checkbox:disabled ~ .button_wrapper .button {
      border-color: sass.$primary;
      background-color: sass.$white;
    }

    .checkbox:checked ~ .button_wrapper .button {
      border-color: sass.$primary;

      .button > .check {
        opacity: 0;
      }
    }
  }
}

@include sass.htmlConditioner('MarppleShop') {
  .button_checkbox {
    &:hover > .button_wrapper > .button {
      border-color: sass.$accent;
    }

    .button_wrapper {
      .button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: sass.ptr(18);
        height: sass.ptr(18);
        transition:
          border-color 300ms ease-out,
          background-color 300ms ease-out;
        border: 1px solid sass.$gray-20;
        border-radius: 4px;
        background-color: sass.$white;
      }

      .check {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        transition: opacity 300ms ease-out;
        opacity: 0;
        color: sass.$white;
      }
    }

    .checkbox:disabled ~ .button_wrapper .button {
      border-color: sass.$gray-50;
      background-color: sass.$gray-50;

      & > .check {
        opacity: 1;
      }
    }

    .checkbox:checked ~ .button_wrapper .button {
      border-color: sass.$accent;
      background-color: sass.$accent;
    }
  }
}

@include sass.htmlConditioner('Ciety') {
  .button_checkbox {
    &:hover > .button_wrapper > .button {
      border-color: sass.$accent;
    }

    .button_wrapper {
      .button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: sass.ptr(18);
        height: sass.ptr(18);
        transition:
          border-color 300ms ease-out,
          background-color 300ms ease-out;
        border: 1px solid sass.$black;
        border-radius: 4px;
        background-color: sass.$white;
      }

      .check {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        transition: opacity 300ms ease-out;
        opacity: 0;
        color: sass.$white;
      }
    }

    .checkbox:disabled ~ .button_wrapper .button {
      border-color: sass.$gray-50;
      background-color: sass.$gray-50;

      & > .check {
        opacity: 1;
      }
    }

    .checkbox:checked ~ .button_wrapper .button {
      border-color: sass.$accent;
      background-color: sass.$accent;
    }
  }
}
