@use '@/ui/style/sass';

.withdrawal_page {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: sass.ptr(512);
  margin: sass.ptr(60) auto;
  gap: sass.ptr(32);
}

ol.list > li {
  padding-left: 1em;
  text-indent: -1.2em;
}

.title {
  display: flex;
  flex-direction: column;
  gap: sass.ptr(8);
  width: 100%;
}

.guide_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: sass.ptr(16);
}

.guide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: sass.ptr(16);
  border-top: 1px solid sass.$gray_20;
  gap: sass.ptr(16);
}

.guide_contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: sass.ptr(8);
}

.guide_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: sass.ptr(24);
  border-radius: sass.ptr(8);
  background: sass.$gray_10;
  gap: sass.ptr(8);

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
  }
}

.popup_guide_contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
  gap: sass.ptr(8);
}

.withdraw_info {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: sass.ptr(10) sass.ptr(16);
  border-radius: sass.ptr(8);
  background: sass.$gray_10;
  gap: sass.ptr(8);

  .item {
    width: 100%;
    text-align: left;
    word-break: break-word;

    @include sass.limitLine(1);

    &::before {
      content: '•';
      margin-right: sass.ptr(7);
      font-size: sass.ptr(14);
    }
  }

  .sub_item {
    width: 100%;
    text-align: left;
    word-break: break-word;

    @include sass.limitLine(1);

    &::before {
      content: ' ';
      margin-right: sass.ptr(14);
    }
  }
}

.buttons {
  display: flex;
  width: 100%;
  gap: sass.ptr(16);

  .cancel {
    flex: 0 0 0;
    min-width: sass.ptr(160);
  }

  .withdrawal {
    flex: 1 0 0;
    min-width: sass.ptr(160);
    max-width: sass.ptr(336);
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .withdrawal_page {
    width: 100%;
    margin: unset;
    padding: sass.ptr(24) sass.ptr(16) sass.ptr(180);
    gap: sass.ptr(24);
  }

  .buttons {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    align-items: center;
    width: 100%;
    padding: sass.ptr(16);
    border-top: 1px solid sass.$gray_5;
    background-color: sass.$white;
    gap: sass.ptr(8);

    @include sass.safeAreaBottomPlus(sass.ptr(16));

    .cancel {
      flex: 1 0 0;
      min-width: unset;
    }

    .withdrawal {
      flex: 1 0 0;
      min-width: unset;
      max-width: unset;
    }
  }
}
