@use '@/ui/style/sass';

html[device='pc'] {
  .button_group_sns {
    display: flex;
    gap: sass.ptr(12);
    margin-top: sass.ptr(12);
  }
}

html[device='mo'] {
  .button_group_sns {
    display: flex;
    gap: sass.ptr(16);
    margin-top: sass.ptr(12);
  }
}
