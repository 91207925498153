@use '@/ui/style/sass';

html[device='pc'] {
  .page_wrapper {
    padding: sass.ptr(60);
    @include sass.flex(center, center, column);
  }

  .mypage {
    display: flex;
    flex-direction: column;
    width: sass.ptr(512);
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(8);
    color: sass.$gray-50;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(32);
    padding-top: sass.ptr(32);
  }

  .profile_image {
    gap: sass.ptr(28);
    @include sass.flex(center, center, column);
  }

  .profile_image_description {
    color: sass.$gray-50;
    text-align: center;
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(8);
  }

  .field_description {
    color: sass.$gray-50;
  }

  .field_description_info {
    display: flex;
    align-items: center;
    margin-top: sass.ptr(8);
    color: sass.$accent;
    gap: sass.ptr(4);
  }

  .input_group_button {
    display: flex;
    gap: sass.ptr(8);
    margin-top: sass.ptr(4);
    margin-bottom: sass.ptr(8);

    > button {
      flex-shrink: 0;
      width: sass.ptr(160);
    }
  }

  .input_group_radio {
    display: flex;
    gap: sass.ptr(24);
  }

  .button_group {
    display: flex;
    gap: sass.ptr(16);

    > :first-child {
      flex-shrink: 0;
      width: sass.ptr(160);
    }
  }

  .button_group_sns {
    display: flex;
    gap: sass.ptr(12);
    margin-top: sass.ptr(12);
  }

  .withdrawal {
    color: sass.$gray-50;
  }

  .address_wrapper {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(4);

    .input_group_button {
      margin-bottom: 0;
    }
  }

  .address_layer {
    display: none;
    position: relative;
    width: 100%;
    height: sass.ptr(300);
    margin: sass.ptr(5) 0;
    border: 1px solid;
  }

  .sns_button_container {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(24);
  }
}

html[device='mo'] {
  .page {
    display: flex;
    flex-direction: column;
  }

  .mypage {
    display: flex;
    flex-direction: column;
    padding: sass.ptr(24);
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(8);
    color: sass.$gray-50;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(32);
    padding-top: sass.ptr(32);
    padding-bottom: sass.ptr(118);
  }

  .profile_image {
    gap: sass.ptr(28);
    @include sass.flex(center, center, column);
  }

  .profile_image_description {
    color: sass.$gray-50;
    text-align: center;
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(8);
  }

  .field:has(.button_group) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: sass.ptr(16);
    border-top: 1px solid sass.$gray-10;
    background-color: sass.$white;

    @include sass.safeAreaBottomPlus(sass.ptr(16));

    .button_group {
      > :first-child {
        display: none;
      }
    }
  }

  .field_description {
    color: sass.$gray-50;
  }

  .field_description_info {
    display: flex;
    align-items: flex-start;
    margin-top: sass.ptr(8);
    color: sass.$accent;
    gap: sass.ptr(4);

    > span {
      padding-top: sass.ptr(2);
    }
  }

  .input_group_button {
    display: flex;
    gap: sass.ptr(8);
    margin-top: sass.ptr(4);
    margin-bottom: sass.ptr(8);

    > button {
      flex-shrink: 0;
      width: sass.ptr(120);
    }
  }

  .input_group_radio {
    display: flex;
    gap: sass.ptr(24);
  }

  .button_group {
    display: flex;
    gap: sass.ptr(16);

    > :first-child {
      flex-shrink: 0;
      width: sass.ptr(160);
    }
  }

  .button_group_sns {
    display: flex;
    gap: sass.ptr(16);
    margin-top: sass.ptr(12);
  }

  .withdrawal {
    color: sass.$gray-50;
  }

  .address {
    display: flex;
  }

  .address_wrapper {
    display: flex;
    flex-direction: column;
    gap: sass.ptr(4);

    .input_group_button {
      margin-bottom: 0;
    }
  }

  .address_layer {
    display: none;
    position: relative;
    width: 100%;
    height: sass.ptr(300);
    margin: sass.ptr(5) 0;
    border: 1px solid;
  }

  .sns_button_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
