@use '@/ui/style/sass';

.label_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: sass.ptr(4);

  @include sass.conditionThemify('MarppleShop') {
    &.only_input_container {
      gap: sass.ptr(8);
    }
  }
}

.input_container {
  display: flex;
  width: 100%;
  gap: sass.ptr(8);
}

.label {
  display: inline-flex;
  align-items: center;
  width: 100%;
  gap: sass.ptr(4);
}

.dot {
  align-self: flex-start;
  width: sass.ptr(4);
  height: sass.ptr(4);
  margin-top: sass.ptr(4);
  border-radius: sass.ptr(4);
  background-color: sass.$accent;
}
