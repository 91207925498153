@use '../sass';

@mixin mini_button {
  @include sass.htmlConditioner(false, 'pc') {
    .button {
      flex-shrink: 0;
      width: sass.ptr(160);
    }
  }

  @include sass.htmlConditioner(false, 'mo') {
    .button {
      width: unset;
    }
  }
}
