@use '@/ui/style/sass';

.tabs {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: flex-start;
  width: 100%;

  @include sass.hiddenScroll;

  & {
    @include sass.snapScroll(0);
  }

  .tab {
    display: flex;
    flex: 1 0 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: sass.ptr(12) sass.ptr(16);
    color: sass.$gray_50;
    text-wrap: nowrap;

    &[data-active='true'] {
      border-bottom: 1px solid sass.$black;
      color: sass.$black;
    }
  }

  .line {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: sass.$gray_20;
  }
}

@include sass.htmlConditioner('Marpple') {
  .tab {
    padding: sass.ptr(20);
  }
}
